// app/javascript/projects/components/ProjectsDisplay.jsx

import React from 'react';
import QualCodeActions from 'actions/QualCodeActions'
import AuthStore from 'stores/AuthStore'
import TermsBlurb from './TermsBlurb'
import DelveAccountBody from './DelveAccountBody'
import DimensionsConstants from 'constants/DimensionsConstants'
import FieldGroup from './FieldGroup'
import LoadingButton from './LoadingButton'
import trackingUtil from 'utils/TrackingUtil'


class SignUpDisplay extends React.Component {
  constructor () {
    super();
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._onChange = this._onChange.bind(this);
    this.state = {
      email: "",
      password: "",
      name: "",
      show: false,
      loading: false
    }
  }

  _onChange () {
    this.setState({
        ...AuthStore.getSignupState(),
      }
    )
  }

  componentDidMount () {
    AuthStore.addChangeListener(this._onChange);
    trackingUtil.logAmplitudeEvent('sign_up_page_viewed');
  }

  componentWillUnmount () {
    AuthStore.removeChangeListener(this._onChange);
  }

  onSubmit(e)
  {
    this.setState({loading: true});
    QualCodeActions.signUp(this.state.name, this.state.email, this.state.password);
    e.preventDefault()
  }

  onNameChange (e) {
    this.setState({
      name: e.target.value,
      loading: false
    });
  }

  onEmailChange (e) {
    this.setState({
      email: e.target.value,
      loading: false
    });
  }

  onPasswordChange (e) {
    this.setState({
      password: e.target.value,
      loading: false
    })
  }

  render () {
    return (
      <DelveAccountBody error={this.state.show ? {message:this.state.message, error:true}: {}}
        subHeader="Create an Account"
        login
        >
      <div style={{width:DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
        <form>
          <FieldGroup
            id="formControlsName"
            type="name"
            label="Name"
            placeholder="Enter name"
            value={this.state.name}
            onChange={this.onNameChange}
          />
          <FieldGroup
            id="formControlsEmail"
            type="email"
            label="Email address"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.onEmailChange}
          />
          <FieldGroup id="formControlsPassword"
                      label="Password"
                      type="password"
                      placeholder="Enter Password"
                      onChange={this.onPasswordChange}
                      value={this.state.password}/>

          <div style={{width: "100%", marginTop:"30px"}}>
            <div style={{width: DimensionsConstants.ACCOUNT_WIDTH, margin: "0 auto"}}>
              <LoadingButton bsStyle="blue"
                                loading={!!this.state.loading && !this.state.show}
                                disabled={!!this.state.loading && !!this.state.show}
                                width="100%"
                                onClick={this.onSubmit}
                                >
                                Sign Up
                              </LoadingButton>
            </div>
          </div>
        </form>

        <TermsBlurb/>
      </div>
      </DelveAccountBody>
    );
  }
}

export default SignUpDisplay;
