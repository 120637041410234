import { IntercomAPI } from 'react-intercom';
import * as amplitude from '@amplitude/analytics-browser';

var trackingUtil = {
  logEvent: function logEvent(eventName, params)
  {
    if ( !eventName ) return;
    
    if ( params )
    {
      IntercomAPI('trackEvent', eventName, params);
    }
    else {
      IntercomAPI('trackEvent', eventName);
    }
  },

  logAmplitudeEvent: function logAmplitudeEvent(eventName, params)
  {
    if ( !eventName ) return;

    try {
      if ( params )
        amplitude.logEvent(eventName, params);
      else
        amplitude.logEvent(eventName);
    } catch (e) {
      console.log(e);
    }
  },

  updateAttribute: (attribute, value) => {
    if ( !!attribute && !!value ) {
      IntercomAPI('update', {[attribute]: value})
    }
  }
}

export default trackingUtil
